import './App.css';


function App() {

  return (

    <div className="App">
      <a class="support-ukraine" href="https://help.unicef.org/ukraine-emergency" target="_blank" rel="nofollow noopener noreferrer" title="Support Ukraine.">
      <div class="support-ukraine__firstlabel">
        We Stand With Ukraine
        </div>
        <div class="support-ukraine__flag" role="img" aria-label="Flag of Ukraine">
          <div class="support-ukraine__flag__blue"></div>
          <div class="support-ukraine__flag__yellow"></div>
        </div>
        <div class="support-ukraine__label">
          Help Provide Humanitarian Aid to Ukraine.
        </div>
      </a>

      <header className="App-header">

          <h1>Under construction</h1>
          <span class="loader"></span>
        
        <h5>I'm revamping my website, stay tuned.</h5>

      </header>

    </div>

  );

}


export default App;